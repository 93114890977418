import React, { useState } from 'react'
import {
    GoogleReCaptchaProvider,
    GoogleReCaptcha
} from "react-google-recaptcha-v3"
import loadable from "@loadable/component"
import * as styles from '../styles/contact.module.css'
import FooterCard from '../components/cards/FooterCard/FooterCard';
import { GatsbySeo, BreadcrumbJsonLd} from 'gatsby-plugin-next-seo';
const Layout = loadable(()=>import("../hoc/Layout/Layout"));
const Card = loadable(()=> import('../components/cards/Card/Card'));
const PrimaryButton = loadable(()=> import('../components/buttons/PrimaryButton/PrimaryButton'));

  

export default function Contact() {
    const [token, setToken] = useState()

    return (
        <Layout>
            <GatsbySeo
                language='en'
                title='Contact - Raysummee'
                canonical='https://www.raysummee.com/contact'
                description="Hey! Angshuman is here. You can contact me directly here. I am a flutter developer, full staker and UI/UX designer. I have developed Cizor, Crena, Raylex, Raylex studio, animore and many other apps"
                openGraph={{
                    url: 'https://www.raysummee.com/contact',
                    title: 'Contact - Raysummee',
                    type: 'website',
                    description: "Hey! Angshuman is here. You can contact me directly here. I am a flutter developer, full staker and UI/UX designer. I have developed Cizor, Crena, Raylex, Raylex studio, animore and many other apps",
                    images: [
                        {
                            url: 'https://www.raysummee.com/images/logo_wb.jpg',
                            width: 800,
                            height: 600,
                            alt: 'Logo',
                        }
                    ],
                    site_name: 'Raysummee',
                }}
            />
            <BreadcrumbJsonLd
                itemListElements={[
                    {
                        position: 1,
                        name: 'Contact',
                        item: 'https://www.raysummee.com/contact',
                    },
                ]}
            />
            <div className={styles.contact_area}>
                <Card className={styles.contactCard}>
                    <h2>Contact</h2>
                    <hr/>
                    <GoogleReCaptchaProvider reCaptchaKey="6Lc4rH4gAAAAAOXInfwecO7MC782tUh-t-TbbZo2">
                        <form className={styles.form} method="POST" action="https://getform.io/f/610a2a8b-6f0f-4cc6-ba71-8f1cc4b4c0d5">
                            <input type="text" placeholder="Name" name="Name" required/>
                            <input type="text" placeholder="Email" name="Email" required/>
                            <textarea placeholder="Message" name="Message" rows={5} required/>
                            <input type="hidden" id="captchaResponse" name="g-recaptcha-response" value={token}></input>
                            <PrimaryButton className={styles.btn} type="submit">
                                Submit
                            </PrimaryButton>
                            <GoogleReCaptcha
                                onVerify={token => {
                                    setToken(token)
                                }}
                            />
                        </form>
                    </GoogleReCaptchaProvider>
                </Card>
                <FooterCard className={styles.footer}/>
            </div>
        </Layout>
    )
}
